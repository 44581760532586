.blogPostContent h1 {
  color: "#051C2C";
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 40px;
  /* Add more styling as needed */
}

.blogPostContent p {
  color: "#696F6D";
  font-weight: 300;
  font-size: 22px;
  /* Add more styling as needed */
}
