.slider {
    height: 250px;
    margin:auto;
    position: relative;
    width: 80%;
    display: grid;
    place-items: center;
    overflow: hidden;
    

}

.slide-track {
    display: flex;
    width: calc(250px*120);
    column-gap: 20px;
    animation: scroll 600s linear infinite;

}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 200 ));
    }
    
}

.slide {
    height: 250px;
    width: 250px;
    display: flex;
    align-items: center;
    margin: 15px;
}

img {
    width: 100%;
}
